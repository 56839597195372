/* Page styling */
body {
  background-color: #0A0A23; /* Dark blue background */
  color: white; /* White text for readability */
  font-family: Arial, sans-serif; /* Default font */
  margin: 0;
  padding: 0;
  text-align: center;
}

header {
  text-align: center;
  padding: 20px 0;
}

/* Title styling */
header h1 {
  color: #bf5707; /* Orange color for the title */
}

footer {
  text-align: center;
  padding: 20px 0;
  color: #bf5707;;
}

/* Demo container styling */
#demo-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 96px; /* 1 inch gap between the demo boxes */
  padding: 20px;
}

/* Individual demo container styling */
/* Adjust .demo-container if needed to style the container of each category */
.demo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

/* Existing styles for .demo, .logo-box, .demo-logo, etc., should still apply */


/* Logo box styling with frame and shadow */
.logo-box {
  display: flex; /* Ensures that the logo is centered */
  justify-content: center; /* Centers logo horizontally */
  align-items: center; /* Centers logo vertically */
  height: 150px; /* Set a fixed height to ensure alignment */
  text-align: center;
  border: 3px solid #ccc; /* Frame around the logo */
  border-radius: 5px; /* Rounded corners for the frame */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Shadow effect */
  padding: 5px; /* Space between the logo and the frame */
  background-color: #f9f9f9; /* Background color for the logo box */
  margin-bottom: 10px; /* Space below the logo box */
}

.demo-logo {
  max-width: 90%; /* Slightly less than 100% to ensure padding is visible */
  max-height: 90%; /* Slightly less than 100% to ensure padding is visible */
  background-color: transparent; /* Transparent background for logos */
}

/* Demo text box styling */
.demo-box {
  width: 100%; /* Ensure this is 100% to fill the container */
  border: 1px solid #bf5707;
  padding: 10px;
  text-align: center;
  background-color: #bf5707; /* Light background for the text boxes */
  border-radius: 5px; /* Rounded corners for the text boxes */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Shadow effect for text boxes */
}

/* Demo title styling */
.demo-box h3 {
  margin: 0 0 10px 0; /* Space between title and link */
  color: white; /* Dark text for the title */
}

/* Demo link styling */
.demo-box a {
  color: blue;
  text-decoration: none;
  display: block; /* Ensures that the link is properly aligned */
  margin-top: 8px; /* Space above the link */
}
.demo-box a:hover {
  text-decoration: underline;
}